@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src:
    url('/assets/fonts/roboto/roboto-400.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-400.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src:
    url('/assets/fonts/roboto/roboto-500.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-500.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src:
    url('/assets/fonts/roboto/roboto-700.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-700.woff') format('woff');
  font-display: swap;
}
