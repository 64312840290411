@import "core/mixins";

.app-tooltip {
  position: absolute;
  max-width: 250px;
  font-size: 14px;
  text-align: center;
  color: #000;
  padding: 8.5px 12px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 32px rgba(0, 0, 0, 0.16);  z-index: 98;
  opacity: 0;
  transition: opacity 1s;
}

.app-tooltip .app-tooltip-pointer {
  content: "";
  position: absolute;
  border-style: solid;
}

.app-tooltip-top .app-tooltip-pointer {
  top: 100%;
  left: 50%;
  margin-left: -.5rem;
  border-width: .5rem;
  border-color: white transparent transparent transparent;
}

.app-tooltip-bottom .app-tooltip-pointer {
  bottom: 100%;
  left: 50%;
  margin-left: -.5rem;
  border-width: .5rem;
  border-color: transparent transparent white transparent;
}

.app-tooltip-left .app-tooltip-pointer {
  top: 50%;
  left: 100%;
  margin-top: -.5rem;
  border-width: .5rem;
  border-color: transparent transparent transparent white;
}

.app-tooltip-right .app-tooltip-pointer {
  top: 50%;
  right: 100%;
  margin-top: -.5rem;
  border-width: .5rem;
  border-color: transparent white transparent transparent;
}

.app-tooltip-show {
  opacity: 1;
}
