

button {
  cursor: pointer;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  outline: none;
}

.btn {
  font-size: 13px;
  font-weight: 400;
  background-color: #000;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  text-decoration: none;
  padding: 15px;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  z-index: 2;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  /*
    fix safari 14.1+ bug when
    button color doesn't change on 'disabled' change
  */
  transition: color .01s;
}

.btn * {
  font-weight: 500;
}

.btn.disabled,
.btn:disabled {
  pointer-events: none;
  background-color: #ccc;
  color: #000;
}

.btn:active {
  background-color: #3b3b3b;
  color: #fff;
  box-shadow: none;
}

.btn.btn-gray {
  background-color: #e6e6e6;
  color: #000;
}

.btn.btn-gray:active {
  color: #000;
  background-color: #000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.btn.btn-white {
  color: #000;
  background-color: #fff;
}

.btn.btn-white:hover {
  color: #000;
  background-color: #e6e6e6;
}

.btn.btn-white:active {
  color: #000;
  background-color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


@media only screen and (min-width: 768px) {
  .btn:hover {
    background-color: #353535;
    color: #fff;
  }

  .btn.btn-gray:hover {
    color: #fff;
    background-color: #808080;
  }

  .btn.btn-white:hover {
    color: #000;
    background-color: #f9f9f9;
  }
}
