@import '~ngx-toastr/toastr.css';

@import './assets/styles/core/fonts.scss';
@import './assets/styles/core/buttons.scss';
@import './assets/styles/core/controls.scss';

@import './assets/styles/components/toast.scss';
@import './assets/styles/components/tooltip';

* {
  font-family: 'Navigo', Arial, sans-serif;
  font-weight: normal;
  color: #000;
}

html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

[hidden] {
  display: none !important;
}

.hidden {
  display: none !important;
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: 450px;
}


.form-controls {
  position: relative;
}

.form-group {
  margin-bottom: 15px;
}

.form-input {
  position: relative;
}

.form-input .success-sign {
  text-align: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 12px;
  background-color: #03af00;
  color: #fff;
  position: absolute;
  bottom: 15px;
  right: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  pointer-events: none;
}

.form-input .success-sign img {
  width: 12px;
  vertical-align: middle;
}

.input-hint {
  display: block;
  text-align: left;
  color: #bdbdbd;
  margin-bottom: 0;
  font-size: 15px;
}

.form-header {
  border-bottom: 1px solid #E8E7E3;
  margin-bottom: 20px;
}

.form-title {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 15px;
}

.form-header .form-title {
  display: inline-block;
  padding: 10px 4px;
  font-weight: bold;
  font-size: 24px;
  color: #000;
  position: relative;
  bottom: -1px;
  border-bottom: 2px solid #fe5f00;
  margin-bottom: 0;
}

.form-title-gray {
  font-size: 16px;
  color: #aeaeae;
  margin-bottom: 10px;
  font-weight: 500;
}

.footer {
  width: 100%;
  margin: 50px auto 20px auto;
  text-align: center;
  background-color: transparent;
  box-sizing: border-box;
}

.global-preloader {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.global-preloader.blocking {
  pointer-events: all;
  background: rgba(255, 255, 255, 0);
  position: absolute;
}

.global-preloader.solid {
  pointer-events: all;
  background: rgba(255, 255, 255, 1);
  position: fixed;
  z-index: 99999;
}

.global-preloader img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 140px;
  height: 140px;
  margin-left: -70px;
  margin-top: -70px;
}

@media only screen and (max-width: 767px) {
  .footer {
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    padding: 15px 15px 20px 15px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 600px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb:horizontal{
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
}

.ruble {
  font-family: 'Roboto', sans-serif;
  font-weight: inherit;
}
