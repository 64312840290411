
.toast-container.toast-bottom-center .toast,
.toast-container.toast-top-center .toast {
  width: fit-content;
  max-width: 70%;
}

.toast-container .ngx-toastr {
  max-width: 400px;
  width: auto;
}

.toast-container .toast-message {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.4;
}
