
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input {
  background: rgba(255, 255, 255, 0.18);
  color: #000;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #e8e7e3;
  box-sizing: border-box;
  padding: 9px 0;
  width: 100%;
  font-size: 16px;
  text-align: left;
}

input:disabled {
  color: gray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-input-placeholder {
  color: #808080;
  text-transform: none;
}

::-moz-placeholder {
  color: #808080;
  text-transform: none;
}

:-ms-input-placeholder {
  color: #808080;
  text-transform: none;
}

:-moz-placeholder {
  color: #808080;
  text-transform: none;
}
